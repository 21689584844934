.about {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  padding: 0 3em;
  scroll-margin: 3.25em;
  justify-content: center;
  z-index: 1;
  padding-bottom: 2em;
  margin: 0 auto;
}
.about .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  padding-top: 1em;
}
.about .title > * {
  margin-bottom: 1em;
}
.about .title > .main {
  padding: 0 1em;
}
.about > * {
  z-index: 1;
}
.about h1 {
  font-size: 3rem;
  font-weight: 100;
  color: white !important;
}
.about a {
  color: white !important;
  font-weight: 300;
  margin-right: 1em;
}
.about a:hover {
  border-bottom: 1px solid var(--text-color);
}
.about .text-emoji {
  padding: 2rem 0;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  color: white !important;
  z-index: 1;
}
.about h1 {
  color: white !important;
}
.about .contact {
  margin: 0;
  margin-bottom: 1em;
}
.about .wrapper h2 {
  margin: 0;
  margin-bottom: 0.5rem;
  color: white !important;
}
.about .wrapper .content {
  flex: auto;
  margin: 0 0.5em;
  margin-right: 0;
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 1em;
  color: white !important;
  text-align: left;
}
.about .wrapper .content * {
  font-style: italic;
}
.about .wrapper .content p {
  font-weight: 300;
  color: white !important;
  padding: 0.25rem 0;
}
.about .wrapper .resume {
  font-size: 1rem;
  line-height: 1.5rem;
}
.about .wrapper .resume h1 {
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  text-decoration: underline;
}
.about .wrapper .resume ul {
  margin: 0.5rem 0;
}
.about .wrapper .resume h2 {
  margin: 0 1em;
  margin-bottom: 0.5rem;
}
.about .wrapper .resume p {
  margin-bottom: 0.5rem;
}
.about .content.skills div {
  margin: 0.5em auto;
}
.about .content.skills h3 {
  border-bottom: 1.25px solid var(--text-color);
  display: inline-block;
  font-weight: 300;
}
.about .photo-wrapper {
  margin: auto auto;
  padding-left: 5em;
}
.about .photo-wrapper .photo {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.about .personal-accordion-summary {
  flex-flow: row-reverse;
}
.about .personal-accordion-summary > div {
  flex-flow: row-reverse;
}
.about .personal-accordion-summary h2 {
  text-align: right;
  display: flex;
  align-self: flex-start;
}

@media (min-width: 426px) {
  .about .content-grid {
    width: calc(100% - 2.5rem);
  }
}
@media (max-width: 426px) {
  .about {
    padding: 0;
    padding-top: 2rem;
  }
  .about .personal-accordion-summary {
    flex-flow: row;
  }
  .about .personal-accordion-summary > div {
    flex-flow: row;
  }
  .about .personal-accordion-summary h2 {
    text-align: left;
    display: flex;
    align-self: flex-start;
  }
  .MuiAccordionDetails-root {
    padding: 0 !important;
  }
  .professional-content,
  .personal-content {
    margin: 0 !important;
    padding: 0 1.5em !important;
    text-align: left !important;
  }
}
@media (min-width: 769px) {
  .about .content-grid {
    width: calc(100% - 5rem);
  }
}
@media (min-width: 1025px) {
  .about .content-grid {
    width: 100%;
  }
}
