.custom-ol {
  display: flex !important;
  flex-flow: row !important;
}
.custom-ol > li {
  margin: 0 1em !important;
}

.custom-root {
  margin: 0 3em !important;
}
.custom-root:hover {
  cursor: e-resize;
}
