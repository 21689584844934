.fractal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  color: var(--text-color);
  background-color: rgba(255, 255, 255, 0);
}
.fractal .canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0);
}
.fractal .canvas #erase {
  opacity: 0 !important;
}
.fractal .fade {
  animation: fade-out 1s linear;
}

.blueprint-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  flex-direction: column;
  display: grid;
  align-items: flex-start;
  padding: 20px;
  z-index: -1;
}
.blueprint-container.fade {
  cursor: none !important;
}
.blueprint-container.fade * {
  cursor: none !important;
}
@keyframes fractal-info-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fractal-info-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.blueprint-container svg {
  width: fit-content;
}
.blueprint-container h1 {
  font-size: 2rem;
  font-weight: 200;
  margin-bottom: 10px;
  border-bottom: 2px solid;
  padding-bottom: 1px;
}
.blueprint-container p {
  font-size: 16px;
  font-weight: 200;
}
.blueprint-container .blueprint-header {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 30px;
  width: 100%;
  max-height: 75vh;
}
.blueprint-container .blueprint-header *::-webkit-scrollbar {
  width: 0.5em;
  scrollbar-gutter: stable both-edges;
  scrollbar-width: thin;
}
.blueprint-container .blueprint-header *::-webkit-scrollbar-thumb {
  background-color: #888;
}
.blueprint-container .blueprint-header * {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}
.blueprint-container .blueprint-header *::-webkit-scrollbar-track {
  background: transparent;
}
.blueprint-container .foot {
  flex: 0 0 100%;
  /* flex-grow, flex-shrink, flex-basis */
  align-self: flex-end;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: end;
  align-content: end;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.5em 1em;
  pointer-events: none;
}
.blueprint-container .foot .fps p {
  text-align: right;
  font-size: 1em;
  color: white !important;
}
.blueprint-container .foot .source a {
  font-size: 1em;
  color: white !important;
  pointer-events: all;
}
.blueprint-container .foot .source a:hover {
  border-bottom: 1px solid white;
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.brush-icon {
  opacity: 0.5;
  transition: all 0.25s linear !important;
}
.brush-icon > * {
  font-size: 1.5em !important;
}

.brush-icon:hover {
  opacity: 1;
  transition: all 0s linear !important;
}
