.portfolio-item {
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  transition: all 0.5s ease-in-out;
  background-color: rgba(44, 48, 59, 0.6862745098);
  backdrop-filter: blur(10px);
  color: var(--alt-text);
  font-weight: 200;
  z-index: 2;
  position: relative;
  flex: 0;
  min-width: 20rem;
  margin: 1em;
  max-width: 85%;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.614);
  transform: scale(1);
  -webkit-font-smoothing: subpixel-antialiased;
}
.portfolio-item * {
  transition: all 0.25s ease-in-out;
}
.portfolio-item .nowrap {
  overflow-x: scroll;
  white-space: nowrap;
  padding: 0;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
}
.portfolio-item .blur {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.75em;
  pointer-events: none;
}
.portfolio-item.modal {
  box-shadow: none;
  min-width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
}
.portfolio-item.modal .body-wrapper {
  background-color: #2c303b !important;
  padding: 20px;
  border-radius: 2em 2em 0 0;
  z-index: 4;
  flex: 0;
  box-shadow: 0px -0.5px 20px 5px rgb(0, 0, 0);
  -webkit-box-shadow: 0px -0.5px 20px 5px rgb(0, 0, 0);
  -moz-box-shadow: 0px -0.5px 20px 5px rgb(0, 0, 0);
}
.portfolio-item.modal .portfolio-item__icon {
  padding-bottom: 0 !important;
}
.portfolio-item.modal .wrapper {
  padding-bottom: 0 !important;
}
.portfolio-item.modal:hover {
  box-shadow: none;
  transform: none;
}
.portfolio-item.modal .portfolio-item__info {
  display: flex;
  flex-direction: row;
  padding: 0.25em 1.1em;
  font-size: 17px;
  font-weight: 200;
  color: rgb(222, 222, 222);
  /* Slightly skews the text */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5), -1px -1px 2px rgb(222, 222, 222);
  /* Light shadow to simulate highlights */
  letter-spacing: 1px;
  /* Slightly increase letter spacing for clarity */
}
.portfolio-item.modal .portfolio-item__title {
  padding-bottom: 0 !important;
}
.portfolio-item:hover {
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.692);
  background-color: #2c303b;
}
.portfolio-item .portfolio-item__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 26px;
  padding: 5px 15px;
  padding-top: 20px;
  font-weight: 200;
  text-align: left;
  text-align: start;
  color: rgba(255, 255, 255, 0.936);
  transform: skewX(-5deg);
  /* Slightly skews the text */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5), -1px -1px 2px rgb(255, 255, 255);
  /* Light shadow to simulate highlights */
  letter-spacing: 1px;
  /* Slightly increase letter spacing for clarity */
}
.portfolio-item.modal .portfolio-item__title {
  padding-top: 0.5em;
}
.portfolio-item .portfolio-item__img {
  display: block;
  min-height: 9em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1em 1em 0px 0px;
}
.portfolio-item .portfolio-item__img.modal {
  z-index: 1;
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  transition: none !important;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  border-radius: 0 !important;
  flex: 1;
  box-shadow: none !important;
  /* Blurred background using ::before */
}
.portfolio-item .portfolio-item__img.modal > img {
  min-height: 100%;
  max-width: 100%;
  /* Maximum width is the width of the container */
  max-height: 100%;
  /* Maximum height is the height of the container */
  height: auto;
  width: auto;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  border-radius: 1em;
  box-shadow: none !important;
}
.portfolio-item .portfolio-item__img.modal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--image-url);
  /* Use the dynamic image URL here */
  background-size: cover;
  background-position: center;
  filter: blur(15px);
  /* Apply blur */
  z-index: 1;
  /* Ensure it is behind the image */
  /* Slight scale-up to avoid gaps */
  box-shadow: none !important;
}
.portfolio-item .wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px 15px;
  font-size: 3rem;
  font-weight: 200;
  text-align: left;
  line-height: 1.5rem;
  text-align: start;
}
.portfolio-item .body-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  border-radius: 1em;
  min-width: 50%;
  flex-grow: 1;
}
.portfolio-item .desc_show_more {
  padding: 0.25rem 0;
  padding-bottom: 0;
  font-size: 0.75rem;
  font-weight: 400;
  border-bottom: 1px solid transparent;
  color: rgba(255, 255, 255, 0.884);
}
.portfolio-item .desc_show_more:hover {
  cursor: pointer;
  border-bottom: 1px solid var(--alt-text);
}
.portfolio-item .portfolio-item__desc_less {
  overflow: hidden;
  max-height: 16vh;
  display: block;
  display: -webkit-box;
  display: -moz-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  font-size: 1rem;
}
.portfolio-item .portfolio-item__desc_all {
  padding: 0 0.5em;
  font-size: 0.8rem;
  font-weight: 200;
  line-height: 1.75;
  text-align: start;
}
.portfolio-item .portfolio-item__icon {
  padding: 1rem 0;
  font-size: 1.5rem;
  font-weight: 100;
  text-align: left;
  margin: 0 0.5em;
  display: inline-flex;
  align-items: center;
}
.portfolio-item .portfolio-item__icon svg {
  margin: 0 0.25rem;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.portfolio-item .portfolio-item__icon i {
  margin: 0 0.25rem;
}
.portfolio-item .portfolio-item__links {
  padding: 0 0 0.75rem;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: transparent;
  border-radius: 1em;
  margin: 0 0.5em;
}
.portfolio-item .portfolio-item__links a {
  margin: 0 0.5em;
  border-bottom: 2px solid transparent;
  align-items: center;
}
.portfolio-item .portfolio-item__links a:hover {
  cursor: pointer;
  border-bottom: 2px solid inherit;
}
.portfolio-item a {
  color: var(--alt-text);
}
.portfolio-item .portfolio-item__links a:hover {
  border-bottom: 2px solid var(--alt-text);
}
.portfolio-item .text-wrapper {
  display: flex;
  flex-direction: column;
  flex: auto;
  margin: auto;
}
.portfolio-item .img-caro-buttons {
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 5;
  flex: 1;
  margin: auto;
  padding: 0 2em;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  align-content: center;
  height: 100%;
  width: 100%;
}
.portfolio-item .img-caro-buttons > * {
  color: white;
  font-weight: bold;
}
.portfolio-item.condensed {
  display: flex;
  flex-direction: row;
  width: 25rem;
  flex: 1;
  min-width: 25rem;
  margin: 1em;
  transition: all 0.25s ease-in-out;
}
.portfolio-item.condensed * {
  transition: all 0.25s ease-in-out;
}
.portfolio-item.condensed .portfolio-item__title {
  flex: 0;
  font-size: 1em;
  margin: 10px 0 5px;
  padding: 0.25em 0.75em;
  font-weight: 200;
  text-align: left;
}
.portfolio-item.condensed .portfolio-item__img {
  min-width: 10rem;
  min-height: 9em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1em 0 0 1em;
}
.portfolio-item.condensed .portfolio-item__img.fancy {
  min-width: 12rem;
  position: relative;
  display: flex;
  justify-content: center;
  /* Center the image horizontally */
  align-items: center;
  /* Center the image vertically */
  overflow: hidden;
  /* Ensure the image doesn't spill out of the container */
}
.portfolio-item.condensed .portfolio-item__img.fancy > img {
  border-radius: 1em 0 0 1em !important;
  width: 100%;
  /* Make the image fill the width */
  height: 100%;
  /* Make the image fill the height */
  object-fit: cover;
  /* This does the magic */
  position: absolute;
  /* Absolute position to allow it to be bigger than the container and still be centered */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Align the center of the image with the center of the div */
}
.portfolio-item.condensed .portfolio-item__desc_less {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  text-overflow: clip;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  font-size: 0.75rem;
  transition: none;
}
.portfolio-item.condensed .wrapper {
  padding: 0 0.25em;
}
.portfolio-item.condensed .desc_show_more {
  font-size: 0.7rem;
  font-weight: 400;
}
.portfolio-item.condensed .desc_show_more:hover {
  cursor: pointer;
  border-bottom: 1px solid var(--alt-text);
}
.portfolio-item.condensed .portfolio-item__icon {
  font-size: 1em;
  padding: 0;
  margin-bottom: 0.25em;
  margin-top: 0.5em;
}
.portfolio-item.condensed .portfolio-item__links {
  font-size: 0.7em;
  padding: 0;
  margin: 0.5em;
  margin-top: 0 !important;
  margin-bottom: 0.5em;
  display: block;
}

.portfolio-item-modal-backdrop {
  display: flex;
  flex-direction: row;
  align-items: end !important;
  justify-content: end !important;
  justify-items: end !important;
  align-content: end !important;
  min-height: 100vh;
}
