.scroll {
  display: flex;
}
.scroll .arrow {
  text-align: center;
  margin: 2rem auto;
  margin-top: 2.5rem;
  padding-bottom: 0.5rem;
  transition: all 2s linear;
}
.scroll .arrow .scroll-text {
  text-align: center;
  font-size: 0.5rem;
  margin-bottom: -0.125rem;
}
.scroll .arrow svg {
  cursor: pointer;
  transition: all 0.25s linear;
}

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.wiggle {
  -webkit-animation: wiggle 2s infinite;
  animation: wiggle 2s infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(10deg);
  }
  45% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(6deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
