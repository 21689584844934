@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
:root {
  --text-color: #ff4136;
  --bg-color: #fff;
  --alt-bg: #ff4136;
  --alt-text: #fff;
}

body::-webkit-scrollbar {
  display: none !important;
}

.Outlined-Text {
  font-family: "Poiret One", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.Etched-Text {
  font-family: "Helvetica", sans-serif;
  font-size: 48px;
  background: linear-gradient(135deg, #f1f1f1 25%, #cccccc 75%);
  color: #D3D3D3;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3), -1px -1px 2px rgba(255, 255, 255, 0.2);
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center;
}

.App {
  color: black;
  background: black !important;
  font-family: "Noto Sans", sans-serif;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}
.App .backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  opacity: 0.5;
  height: 100%;
}

.blur-container {
  background-color: rgba(44, 48, 59, 0.6862745098);
  border-radius: 0.5em;
  padding: 0.5em 1em;
  width: fit-content !important;
  border: 3px solid rgba(255, 255, 255, 0.85);
  opacity: 1;
  transition: background-color 1s ease-out, opacity 0.5s 1s ease-out, border 0.5s ease-out, color 1.75s ease-out, width 1.5s ease-out, height 1.5s ease-out, padding 1.5s ease-out, border-radius 1.5s ease-out, box-shadow 1.5s ease-out;
  animation: blur-in 0.5s forwards;
  color: rgb(255, 255, 255) !important;
  /* Let's define an animation: */
}
.blur-container.no-blur {
  background-color: rgba(44, 48, 59, 0.7294117647);
  animation: blur-out 1.5s 0.25s ease-out both;
}
.blur-container * {
  color: white !important;
}
.blur-container.slide-in-left {
  animation: slide-in-left-then-blur 1s ease-out forwards;
}
.blur-container.slide-out-left {
  opacity: 0;
  background: rgba(255, 255, 255, 0);
  animation: slide-out-left-while-fading 0.5s 0.1s ease-out forwards;
}
.blur-container.slide-in-right {
  animation: slide-in-right-then-blur 0.5s ease-out forwards;
}
.blur-container.dark {
  background: rgba(0, 0, 0, 0.5);
  border: 3px solid rgba(0, 0, 0, 0.5);
}
.blur-container.dark p,
.blur-container.dark h1,
.blur-container.dark h2,
.blur-container.dark h3,
.blur-container.dark h4,
.blur-container.dark h5,
.blur-container.dark h6 {
  color: white !important;
}
.blur-container.yellow-border {
  border: 3px solid #ffee00;
}
.blur-container.no-border {
  border: none;
}
.blur-container.shadow {
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.blur-container.hidden-complete {
  opacity: 0;
  background: rgba(255, 255, 255, 0);
  transition: background 0.5s ease-in, opacity 0.5s ease-in;
  animation: blur-out 0.2s 0.5s ease-in forwards;
  /* Important */
}
.blur-container.hidden-bg {
  background: rgba(175, 194, 213, 0);
  color: rgba(255, 255, 255, 0.094);
  border: 2px solid rgba(255, 255, 255, 0);
  box-shadow: none;
  transition: background 0.5s 0.5s ease-in, border 0.5s 0.5s ease-in, color 0.5s ease-in;
  animation: blur-out 0.2s 0.15s ease-in both;
  /* Important */
}
@keyframes slide-in-left-then-blur {
  0% {
    transform: translateX(-100vw);
    backdrop-filter: blur(0px);
  }
  50% {
    transform: translateX(0);
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(10px);
  }
}
@keyframes slide-out-left-while-fading {
  0% {
    transform: translateX(0);
    backdrop-filter: blur(10px);
  }
  95% {
    backdrop-filter: blur(10px);
  }
  100% {
    transform: translateX(-100vw);
    backdrop-filter: blur(0px);
  }
}
@keyframes slide-in-right-then-blur {
  from {
    transform: translateX(100vw);
    backdrop-filter: blur(0px);
  }
  to {
    transform: translateX(0);
    backdrop-filter: blur(10px);
  }
}
@keyframes blur-in {
  100% {
    backdrop-filter: blur(10px);
  }
}
@keyframes blur-out {
  from {
    backdrop-filter: blur(10px);
  }
  to {
    backdrop-filter: blur(0px);
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.triple-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
}

.js-scroll {
  opacity: 0;
}
.js-scroll.scrolled {
  opacity: 1;
}

.fade-out-top {
  animation: fade-out-top 0.5s linear;
  animation-fill-mode: forwards;
}

.fade-out-top-nav {
  animation: fade-out-top 1.25s linear;
  animation-fill-mode: forwards;
}

.nav-start {
  transform: translateY(-50px);
}

.fade-in {
  animation: fade-in 0.5s ease-out forwards;
}

.fade-out {
  animation: fade-out 0.5s ease-in forwards;
}

.fade-in-intro {
  animation: fade-in 2s linear forwards;
}

.fade-in-bottom {
  animation: fade-in-bottom 1.25s linear;
  animation-fill-mode: both;
}

.fade-in-top {
  animation: fade-in-top 1.25s linear both;
}

.slide-in-left {
  animation: slide-in-left 0.5s ease-out both;
}

.slide-out-left {
  animation: slide-out-left 0.5s ease-in both;
}

.slide-in-right {
  animation: slide-in-right 0.5s ease-out both;
}

.slide-out-right {
  animation: slide-out-right 0.5s ease-in both;
}

.fade-in-grow {
  animation: fade-in-grow 0.5s ease-out both;
}

.fade-out-shrink {
  animation: fade-out-shrink 0.5s ease-in both;
}

.hidden-y-scale {
  opacity: 0;
  transform: scaleY(0);
  pointer-events: none;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
    pointer-events: none;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    pointer-events: unset;
  }
}
@keyframes slide-out-left {
  from {
    transform: translateX(0);
    opacity: 1;
    pointer-events: unset;
  }
  to {
    transform: translateX(-100vw);
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(100vw);
    opacity: 0;
    pointer-events: none;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    pointer-events: unset;
  }
}
@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
    pointer-events: unset;
  }
  100% {
    transform: translateX(100vw);
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes fade-in-bottom {
  0% {
    transform: translateY(-50px);
    opacity: 0;
    pointer-events: none;
  }
  50% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: unset;
  }
}
@keyframes fade-in-top {
  0% {
    transform: translateY(-200px);
    opacity: 0;
    pointer-events: none;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    pointer-events: unset;
  }
}
@keyframes fade-out-top {
  0% {
    opacity: 1;
    pointer-events: none;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
    pointer-events: none;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: unset;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    pointer-events: none;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes fade-in-grow {
  0% {
    opacity: 0;
    transform: scale(0, 0);
    pointer-events: none;
  }
  75% {
    transform: scale(1.5, 1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    pointer-events: unset;
  }
}
@keyframes fade-out-shrink {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    pointer-events: none;
  }
  100% {
    transform: scale(0.25, 0.25);
    opacity: 0;
    pointer-events: none;
  }
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
