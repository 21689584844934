nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  z-index: 100000;
  pointer-events: none;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.925));
  color: white !important;
  padding: 0 1rem;
  opacity: 0;
}
nav.sticky {
  transition: all 0.25s linear;
  backdrop-filter: blur(6px);
  pointer-events: all;
  box-shadow: 0px 12px 30px -8px rgb(0, 0, 0);
}
nav.modal-open {
  backdrop-filter: blur(0px) !important;
  background: transparent !important;
  box-shadow: none !important;
}
nav .left-container {
  justify-self: flex-start;
  margin-left: 1rem;
  display: -ms-flexbox;
  display: inline-flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: row;
  align-items: center;
  visibility: hidden;
  pointer-events: none;
}
nav .left-container * {
  margin-right: 0;
  padding: 0.25rem 0;
  font-size: 1.85rem;
  font-weight: 300;
  border-bottom: 2px solid transparent;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black !important;
}
nav .left-container *:hover {
  cursor: pointer;
  border-bottom: 2px solid var(--text-color);
}
nav .magic-wand {
  cursor: pointer;
  justify-self: flex-start;
  margin-left: 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
nav .magic-wand .magic-text {
  text-align: center;
  font-size: 0.5rem;
  padding-top: 0.25rem;
}
nav .magic-wand.bounce-xy {
  -webkit-animation: bounce-xy 2s infinite;
  animation: bounce-xy 2s infinite;
}
nav .menu {
  font-family: "Inconsolata", monospace;
  margin-right: 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
nav .menu__item {
  padding: 0.25rem 0;
  margin: 0 1rem;
  font-size: 1.25rem;
  font-weight: 400;
  border-bottom: 2px solid transparent;
}
nav .menu__item:hover {
  cursor: pointer;
  border-bottom: 2px solid var(--text-color);
}

@media (max-width: 425px) {
  nav {
    height: 4rem;
  }
}
@-webkit-keyframes bounce-xy {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  40% {
    -webkit-transform: translateY(2px) translateX(2px);
    transform: translateY(2px) translateX(2px);
  }
  60% {
    -webkit-transform: translateY(1px) translateX(1px);
    transform: translateY(1px) translateX(1px);
  }
}
@keyframes bounce-xy {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  40% {
    -webkit-transform: translateY(2px) translateX(2px);
    transform: translateY(2px) translateX(2px);
  }
  60% {
    -webkit-transform: translateY(1px) translateX(1px);
    transform: translateY(1px) translateX(1px);
  }
}
