/* Typography */
.md {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  padding: 0em 2em;
  margin-top: 2em;
  margin-left: 0;
  /* Links */
  /* Lists */
  /* Code blocks */
  /* Blockquotes */
  /* Images */
}
.md h1,
.md h2,
.md h3,
.md h4,
.md h5,
.md h6 {
  font-weight: bold;
  margin-bottom: 0.25em;
  text-decoration: underline;
}
.md h1 {
  font-size: 2em;
}
.md h2 {
  font-size: 1.75em;
}
.md h3 {
  font-size: 1.5em;
}
.md h4 {
  font-size: 1.25em;
}
.md h5 {
  font-size: 1em;
}
.md h6 {
  font-size: 0.875em;
}
.md p {
  margin-bottom: 1.5em;
}
.md a {
  color: #337ab7;
  text-decoration: none;
}
.md a:hover {
  text-decoration: underline;
}
.md ul,
.md ol {
  margin-bottom: 1.5em;
}
.md li {
  margin-bottom: 0.5em;
}
.md pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 0.9em;
  line-height: 1.2;
  overflow: auto;
  padding: 1em;
}
.md code {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-family: "Courier New", Courier, monospace;
  font-size: 0.9em;
  padding: 0.25em 0.5em;
}
.md blockquote {
  background-color: #f8f8f8;
  border-left: 5px solid #ccc;
  margin: 0 0 1.5em;
  padding: 0.5em 1em;
}
.md img {
  max-width: 100%;
  height: auto;
}
