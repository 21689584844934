.menu__item {
  padding: 0.25rem 0;
  margin: 0 1rem;
  font-size: 1.25rem;
  font-weight: 400;
  border-bottom: 2px solid transparent;
}
.menu__item:hover {
  cursor: pointer;
  border-bottom: 2px solid var(--text-color);
}
