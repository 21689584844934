.portfolio {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  scroll-margin: 3em;
  transition: all 0.25s ease-in-out;
  width: 100%;
}
.portfolio .contact {
  padding: 0em !important;
}
.portfolio > * {
  transition: all 0.25s ease-in-out;
}
.portfolio h1 {
  padding: 1rem 0;
  font-size: 3rem;
  z-index: 1;
  font-weight: 200;
}
.portfolio .content-grid {
  -ms-flex: auto;
  flex: auto;
  z-index: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  /* Hide scroll bars in Firefox */
}
.portfolio .content-grid .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 3em;
  width: auto;
  padding-top: 1em;
}
.portfolio .content-grid .title > * {
  margin-bottom: 1em;
}
.portfolio .content-grid .title > div {
  padding: 0 1em;
  border-radius: 0.5em;
  backdrop-filter: blur(20px);
}
.portfolio .content-grid .title > button {
  background-color: rgba(255, 255, 255, 0.35);
  padding: 0.25em;
  border-radius: 0.5em;
  margin-left: 0.5em;
  backdrop-filter: blur(20px);
}
.portfolio .content-grid *::-webkit-scrollbar {
  display: none !important;
}
.portfolio .content-grid *::-webkit-scrollbar {
  width: 0.5em;
  scrollbar-gutter: stable both-edges;
  scrollbar-width: thin;
}
.portfolio .content-grid *::-webkit-scrollbar-thumb {
  background-color: #888;
}
.portfolio .content-grid * {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}
.portfolio .content-grid *::-webkit-scrollbar-track {
  background: transparent;
}
.portfolio .portfolio-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  flex: 1 1;
  justify-items: center;
  align-content: center;
}

@media (max-width: 426px) {
  .portfolio {
    padding: 0;
    padding-top: 2rem;
  }
  .portfolio-wrapper {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
  }
}
