.transition {
  pointer-events: none;
}
.transition.fade {
  opacity: 1;
}
.transition.fade.in {
  animation: fade-in 0.5s ease-out;
  pointer-events: inherit;
}
.transition.fade.out {
  animation: fade-out 0.5s ease-in;
  opacity: 0;
  pointer-events: none;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
