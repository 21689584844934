.landing {
  min-height: 100vh;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  padding: 0 3em;
  scroll-margin: 3.25em;
  justify-content: center;
  z-index: 1;
  padding-bottom: 2em;
}
.landing main {
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  padding: 3.25rem 1.5rem 1rem;
  text-align: center;
  color: rgb(0, 0, 0);
  z-index: 1;
}
.landing main .intro-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  height: fit-content;
  width: fit-content;
  margin: auto;
  padding: 1.5rem;
  border-radius: 1em;
}
.landing main .intro-wrapper .intro {
  font-size: 3.5rem;
  padding-bottom: 1rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing main .intro-wrapper .intro * {
  transition: all 0.15s linear;
}
.landing main .intro-wrapper .intro .menu {
  /* offset-x | offset-y | blur-radius | color */
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-family: "Inconsolata", monospace;
}
.landing main .intro-wrapper .intro .menu .menu__item {
  padding: 0.25rem 0;
  margin: 0 1rem;
  font-size: 1.25rem;
  font-weight: 400;
  border-bottom: 2px solid white;
}
.landing main .intro-wrapper .intro .menu .menu__item:hover {
  cursor: pointer;
  border-bottom: 2px solid white;
}
.landing main .intro-wrapper .tagline {
  font-size: 1.375rem;
  margin: 1.5rem 0;
  font-weight: 300;
  z-index: 1;
}
.landing .social {
  padding: 0.25rem 0;
  font-size: 3rem;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.51);
}
.landing .social a {
  padding: 0 0.625rem;
}
.landing .social a.devto svg {
  margin-bottom: -0.25rem;
  width: 2.7rem;
  height: 2.7rem;
  color: transparent !important;
}
.landing .social.animate-icons a {
  display: inline-block;
}
.landing .social.animate-icons a:hover, .landing .social.animate-icons a:focus, .landing .social.animate-icons a:active {
  -webkit-animation-name: icon-wobble;
  animation-name: icon-wobble;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@media (max-width: 425px) {
  .social {
    font-size: 2.5rem;
  }
}
@media (max-width: 425px) {
  .social a.devto svg {
    margin-bottom: -0.225rem;
    width: 2.25rem;
    height: 2.25rem;
  }
}
@-webkit-keyframes icon-wobble {
  25% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  75% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes icon-wobble {
  25% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  75% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
