/* Minimal CSS Reset */
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

html {
  font-size: 12px;
  scroll-behavior: smooth;
}

@media (min-width: 376px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 426px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 769px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 1440px) {
  html {
    font-size: 20px;
  }
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-weight: 200;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

h1 {
  font-weight: 200;
}

.content-grid {
  margin: 0 auto;
  width: calc(100% - 5rem);
}

@media (min-width: 426px) {
  .content-grid {
    width: calc(100% - 5rem);
  }
}
@media (min-width: 769px) {
  .content-grid {
    width: calc(100% - 10rem);
  }
}
@media (min-width: 1025px) {
  .content-grid {
    width: calc(100% - 15rem);
  }
}
a {
  text-decoration: none;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
